import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import Header from "./Header";
import Footer from "../../landingPage/components/Footer";
import { setConfigLoading, setConfig } from "../../../redux/slices/configSlice";

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const config = useSelector((state) => state.config);
  const loading = useSelector((state) => state.config.configLoading);
  let viewer_key = useSelector((state) => state.config.viewer_key);
  const base_url = useSelector((state) => state.config.base_url);
  const [theme,setTheme] = useState('versuz')

  

  useEffect(() => {
    let path = location.pathname.split("/");
    let url = path[1];

    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    

    if (!config.name) {
      fetch(
        `${base_url}api/get_config.php?id=${url}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          
          dispatch(setConfigLoading(false));
          dispatch(setConfig(data));

          if(data.theme){
            setTheme(data.theme)
          }

          console.log(data)
        })
        .catch((err) => {
          navigate("/");
        });
    }
  }, [viewer_key]);

  return (
    <>
      {loading ? (
        <div className="h-screen bg-white flex flex-col justify-center gap-3">
          <span className=" self-center loading loading-dots loading-lg bg-base-100"></span>
          <span className="text-base-100 self-center">Loading competition</span>
        </div>
      ) : (
        <div className=" relative bg-white" data-theme={theme}>
          <Header />
          <div>
            <button onClick={()=>{
              navigate(-1)
            }} className=" m-3 px-5 bg-base-100 py-1 rounded-full">Back</button>
          </div>
          <div className="px-[10px] md:px-[30px]">
            <Outlet />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
