import React, { useEffect, useState } from "react";
import Fixtures from "./Fixtures";

import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Members from "./components/Members";
import Loading from "./components/Loading";
import TeamStats from "./components/TeamStats";
import { Helmet } from "react-helmet";

import maleTeam from "../../assets/images/male-team.png"
import femaleTeam from "../../assets/images/female-team.png"

export function TeamLogo({ src, gender }) {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [url, setUrl] = useState(gender == 'male' ? maleTeam : femaleTeam);

  return (
    <>
    
      <img
        className={`w-[200px] aspect-square rounded-full self-center border shadow-lg`}
        src={loading || hasError ? url : src}
        alt=""
        onLoad={() => {
          
          setLoading(false);
          setIsLoaded(true);
        }}
        onError={() => setHasError(true)}
      />
    </>
  );
}

export default function TeamDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config);
  let viewer_key = useSelector((state) => state.config.viewer_key);
  const base_url = useSelector((state) => state.config.base_url);

  let path = location.pathname.split("/");
  let url = path[1];
  let id = path[3];

  const [view, setView] = useState("members");
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [logo, setLogo] = useState("");
  const [players, setPlayers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const [gameStats, setGameStats] = useState([]);
  const [playerStats, setPlayerStats] = useState([]);
  const [playersDetails, setPlayerDetails] = useState([]);
  const [playerStatDetails, setPlayerStatDetails] = useState([]);
  const [gender, setGender] = useState('male');

  const handleChange = (e) => {
    const { view } = e.target.dataset;
    setView(view);
  };
  
  useEffect(() => {
    
    


    
    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    fetch(
      `${base_url}api/get_team_details.php?id=${id}&url=${url}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.status) {
          setName(data.team_details.name);
          setLogo(data.team_details.logo);
          setAbout(data.team_details.about);
          setGender(data.team_details.gender);
          setPlayers(data.players);
          setPlayerDetails(data.playerss);
          setAdmins(data.admins);
          setGameStats(data.game_stats);

          // console.log(data.team_details.gender)
          
          let statHeaders = Object.keys(data.player_stats);
          setPlayerStats(statHeaders);

          setPlayerStatDetails(data.player_stats);

          setLoading(false);
        } else {
          alert("Error fetching team details");
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err)
        
        // alert("Error fetching team detailsx");
        // navigate(-1);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loading text={"Loading team details"} />
      ) : (
        <div className="mt-[50px] md:mt-[80px] max-w-[1200px] mx-auto ">
          <Helmet>
            <title>{config.name} - {name}</title>
            <meta name="description" content={`${config.name} -  Fixtures`} />
          </Helmet>
          <div className="bg-gray-100 p-[10px] py-[30px] flex flex-col justify-center">
            <TeamLogo
              src={`${base_url}uploads/${logo}`}
              gender={gender}
              alt=""
            />

            <h1 className="text-2xl font-extrabold mt-[20px] text-base-100 text-center">
              {name}
            </h1>
            <p className="text-center text-sm text-base-100 leading-normal mt-2 max-w-[600px] mx-auto">
              {about}
            </p>
          </div>

          <nav className="mt-[30px] flex gap-5 justify-center ">
            <button
              onClick={handleChange}
              data-view="members"
              className={`text-sm ${view == "members" && "border-2 border-base-100"}  text-base-100 rounded-full py-[5px] px-[20px]`}
            >
              Members
            </button>
            <button
              onClick={handleChange}
              data-view="fixtures"
              className={`text-sm ${view == "fixtures" && "border-2 border-base-100"}  text-base-100 rounded-full py-[5px] px-[20px]`}
            >
              Fixtures
            </button>
            <button
              onClick={handleChange}
              data-view="stats"
              className={`text-sm ${view == "stats" && "border-2 border-base-100"}  text-base-100 rounded-full py-[5px] px-[20px]`}
            >
              Statistics
            </button>
          </nav>

          <div className="mt-[30px]">
            {view == "members" && (
              <>
                <Members category="Admins" data={admins} />
                <Members gender={gender} category="Players" data={players} />
              </>
            )}
            {view == "fixtures" && <Fixtures teamId={id} />}
            {view == "stats" && (
              <TeamStats
                gameStats={gameStats}
                playerStats={playerStats}
                playerStatDetails={playerStatDetails}
                playersDetails={playersDetails}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
