import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import maleIcon from "../../../assets/images/male-icon.png"
import femaleIcon from "../../../assets/images/female-icon.png"

export function MemberPicture({ src, gender }) {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [url, setUrl] = useState(gender == 'female' ? femaleIcon : maleIcon);

  return (
    <>
      <img
        className={`border w-full aspect-square`}
        src={loading || hasError ? url : src}
        alt=""
        onLoad={() => {
          
          setLoading(false);
          setIsLoaded(true);
        }}
        onError={() => setHasError(true)}
      />
    </>
  );
}


const Member = ({ data,gender }) => {
    const base_url = useSelector((state) => state.config.base_url);
    const [name,setName] = useState([])

    useEffect(()=>{
        setName(data.name.split(' '))
    },[])
  return (
    <NavLink to="">
      
      <div className="relative">


      <MemberPicture gender={gender} src={`${base_url}uploads/${data.picture}`} />
        

        <div className=" absolute bg-base-100 bg-opacity-80 w-full bottom-0 p-3">
          <p className="text-white text-sm mt-3">{data.position}</p>
          <p className="text-white text-md font-bold">{name[0]}</p>
          <p className="text-white text-xl font-black">{name[1]}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default function Members({ category, data,gender }) {
  if (data.length) {
    return (
      <div className="mt-[50px]">
        <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-start ">
          Team {category}
        </h1>

        <div className="mt-4">
          {/* <h1 className="text-xl text-base-100 font-bold">Coaches</h1> */}
          <div className="grid  grid-cols-2 md:grid-cols-4 gap-4">
            {data.map((d, i) => (
              <Member gender={gender} key={i} data={d} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
