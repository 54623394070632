import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBracket } from "bracketry";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";

export default function Bracket() {
  const config = useSelector((state) => state.config);
  const base_url = useSelector((state) => state.config.base_url);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  const [loading, setLoading] = useState(true);
  const [bracketAvailable, setBracketAvailable] = useState(true);

  const elementRef = useRef(null);
  const location = useLocation();

  let url = `${base_url}api/get_bracket_data.php?uid=${config.season}`;

  const requestOptions = {
    headers: {
      viewer_key: viewer_key,
    },
  };

  useEffect(() => {
    let path = location.pathname.split("/");
    let id = path[1];
    

    fetch(`${base_url}api/get_bracket_data.php?id=${id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setLoading(false);

        if (data.rounds.length) {
          setTimeout(()=>{
            const wrapper = document.querySelector("#tree-wrapper-element");
            // console.log(wrapper)
            wrapper.innerHTML = "";
            const options = {
              // useClassicalLayout: true,
              // verticalScrollMode: 'buttons',
              // visibleRoundsCount: 1,
              getNationalityHTML: (player) => {
                // return `<img style="width: 30px;" src="${player.logo}">`;
                return ``;
                // return `<img style="width: 30px; border-radius:50%" src="${player.logo}">`;
              },
              getRoundTitleElement: (roundData, roundIndex) => {
                const el = document.createElement("div");
                el.classList.add(
                  "text-sm",
                  "font-bold",
                  "text",
                  "py-4",
                  "font-pilat",
                  "text-base-100"
                );
                el.innerHTML = roundData.name;
                return el;
              },
            };
            createBracket(data, wrapper, options);

          },1000)
        } else {
          setBracketAvailable(false);
        }
      })
      .catch((err) => {
        if (err.message != "wrapper is null") {
          alert(`Error getting barcket data ${err.message}`);
        }

        // navigate("/");
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loading text="Loading bracket data" />
      ) : (
        <div>
          <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-center mt-4">
            Competition Bracket {!bracketAvailable && "Unavailable"}
          </h1>
          <div className="flex justify-center mt-4 w-full">
            <div
              id="tree-wrapper-element"
              className="mx-auto overflow-scroll"
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
