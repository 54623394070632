import React from "react";
import logo from "../../../assets/images/versuz full logo.png";
import fb from "../../../assets/images/fb-icon.svg";
import ig from "../../../assets/images/ig-icon.svg";
import linkedin from "../../../assets/images/linkedin-icon.svg";
import youtube from "../../../assets/images/youtube-icon.svg";


export default function Footer() {
  return (
    <footer className="mt-[110px] pt-[150px] px-[20px] md:px-[40px] bg-black">
      <div className="grid lg:grid-cols-2  max-w-[1400px] mx-auto">
        <div>
          <img src={logo} alt="" />

          <p className="text-white mt-[30px] max-w-[450px] leading-normal opacity-80">
            We’re bridging the gap between sports and technology in Nigeria by
            providing easily accessible and affordable tools that empower
            smaller competitions to embrace digitization and unlock growth and
            monetization opportunities
          </p>

          <div className="socials mt-[40px] flex gap-[23px] align-center">
            {/* <img src={fb} alt="" className="w-[40px] opacity-50 aspect-square" /> */}
            <a href="https://www.instagram.com/versuz.co/profilecard/?igsh=bDJzMGd5ZGJyNTg1">
            <img src={ig} alt="" className="w-[40px] opacity-50 aspect-square" />
            </a>
            <a href="https://www.linkedin.com/company/versuzco/posts/?feedView=all">
              <img src={linkedin} alt="" className="w-[40px] opacity-50 aspect-square" />
            </a>
            {/* <img src={youtube} alt="" className="w-[40px] opacity-50 aspect-square" /> */}
          </div>

          <div className="contact"></div>
        </div>
        <div className=" grid gap-[20px] md:grid-cols-3 mt-[50px] md:mt-[100px] lg:mt-[0px]">
          <div className="">
            <p className="text-xl text-purple font-bold">Contact us</p>
            <div className="contact mt-[20px]">
              <p className="mt-[10px] text-white opacity-80">Partnerhsips@versuz.co</p>
              <p className="mt-[10px] text-white opacity-80"> Support@versuz.co</p>
              <p className="mt-[10px] text-white opacity-80"> +234 911 314 6539</p>
            </div>
          </div>
          <div className="">
            <p className="text-xl text-purple font-bold">Solutions</p>

            <div className="contact mt-[20px]">
              <p className="mt-[10px] text-white opacity-80">Admin portal</p>
              <p className="mt-[10px] text-white opacity-80">Enterprise</p>
              <p className="mt-[10px] text-white opacity-80">Competition hosting</p>
              <p className="mt-[10px] text-white opacity-80">Websites</p>
            </div>
          </div>
          <div className="">
            <p className="text-xl text-purple font-bold">Terms & Policies</p>
            <div className="contact mt-[20px]">
              <p className="mt-[10px] text-white opacity-80">Terms of use</p>
              <p className="mt-[10px] text-white opacity-80">Privacy Policy </p>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright  mt-[60px] py-[50px]">
        <p className="text-center text-white opacity-60">
          © Copyright Versuz.co 2024 - All rights reserved
        </p>
      </div>
    </footer>
  );
}
