import React, { useEffect, useState } from "react";
import check from "../../../assets/images/check.svg";
import { useSelector, useDispatch } from "react-redux";

const Element = ({ title, body }) => {
  return (
    <div className="self-center mb-[20px] md:mb-auto">
      <div className=" flex md:flex-col lg:flex-row align-center gap-3 ">
        <div className="w-[40px] aspect-square bg-base-100 md:mx-auto lg:mx-0 rounded-sm flex justify-center ">
          <img src={check} alt="" className="w-[20px] h-[20px]  self-center" />
        </div>

        <p className="lg:text-xl  md:text-center font-bold self-center text-base-100">
          {title}
        </p>
      </div>
      <p className="mt-[10px] md:text-center  lg:text-start text-base-100 max-w-[500px] text-sm ">
        {body}
      </p>
    </div>
  );
};

export default function Integrate() {
  const [elements, setElements] = useState([
    {
      title: "Targeted Branding Opportunities",
      body: "Showcase your brand with prime placements that connect directly with fans. From website banners to competition screens, get maximum visibility and meaningful exposure throughout affiliated competitions.",
    },
    {
      title: "Customizable Ad Placements",
      body: "Tailor competition themes to align with your brand. Customize ad placements and event visuals for a seamless integration that enhances brand identity and engages the audience effectively.",
    },
    {
      title: "Seamless Partnership Management",
      body: "Partner with multiple event organizers and track your brand’s impact across affiliated competitions. Our unified platform simplifies collaboration and provides a collective overview of your sponsorship performance.",
    },
    {
      title: "Corporate Social Responsibility",
      body: "Align your brand with impactful sports initiatives that promote community well-being, engage youth, and foster social development, all while enhancing your company's reputation and fulfilling your CSR goals.",
    },
  ]);

  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const base_url = useSelector((state) => state.config.base_url);

  const handleChange = (e) => {
    const { mod } = e.target.dataset;
    const { value } = e.target;

    switch (mod) {
      case "name":
        setName(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
    }
  };

  useEffect(() => {
    if (name && company && email && phone) {
      setDisabled(false);
    }
  }, [name, company, email, phone]);

  const submit = () => {
    const payload = { name, company, email, phone };

    setLoading(true);
    const requestOptions = {
      method: "GET",
    };

    console.log(`${base_url}api/add_partnership_request.php?name=${name}&company=${company}&email=${email}&phone=${phone}`)

    fetch(
      `${base_url}api/add_partnership_request.php?name=${name}&company=${company}&email=${email}&phone=${phone}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
      });

    
  };

  return (
    <section
      data-theme="versuz"
      className="solutions bg-purple-100 rounded-[40px] px-[20px] md:px-[50px]  py-[80px] max-w-[1400px] mx-auto mt-[100px] text-base-100 "
    >
      <h1 className=" text-center font-extrabold font-pilat text-base-100 text-[22px] md:text-[32px] lg:text-3xl tracking-wide">
        Integrate Your Brand for <br />{" "}
        <span className="text-tomato font-pilat">Maximum Impact</span>
      </h1>

      <p className="text-base-100 max-w-[606px] text-cemter mx-auto mt-[18px] ">
        <span className="hidden md:inline">
          We’ve made it easy for sponsors to work with organizers!{" "}
        </span>{" "}
        Our platform provides customizable branding opportunities, real-time
        engagement metrics, and targeted exposure to the right audience,
        ensuring a mutually beneficial partnership that drives visibility and
        return on investment.
      </p>

      <div className="mt-[80px] grid lg:grid-cols-2 lg:px-[60px] lg">
        <div className=" flex justify-center order-2 lg:order-1 mt-[80px] md:mt-0">
          <div
            action=""
            className="bg-base-100 w-full max-w-[400px] md:max-w-[600px] lg:max-w-[400px] py-[50px] px-[35px] rounded-[20px] self-center"
          >
            <h1 className="text-xl text-white text-center font-semibold">
              Partner With Versuz For <br />
              <span className="text-tomato">Maximum Impact</span>
            </h1>

            {submitted ? (
              <div>
                <p className="text-white text-center mt-4 opacity-70">
                  We’ve received your information and will review it shortly. A
                  member of our team will reach out to you soon to discuss the
                  next steps. We’re excited about the potential of partnering
                  with you!
                  <br />
                  <br />
                  Want to speed up the process? Give us a call{" "}
                  <b>+234 911 314 6539</b>
                </p>
              </div>
            ) : (
              <>
                <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-5">
                  <label className="form-control w-full max-w-xs mt-[5px] ">
                    <div className="label">
                      <span className="label-text">Your name</span>
                    </div>
                    <input
                      type="text"
                      placeholder="John Doe"
                      data-mod="name"
                      value={name}
                      onChange={handleChange}
                      className="input input-bordered w-full max-w-xs bg-purple-100 input-md"
                    />
                  </label>

                  <label className="form-control w-full max-w-xs mt-[5px] ">
                    <div className="label">
                      <span className="label-text">Company name</span>
                    </div>
                    <input
                      type="text"
                      data-mod="company"
                      value={company}
                      onChange={handleChange}
                      placeholder="Doe & Dodo Holdings"
                      className="input input-bordered w-full max-w-xs bg-purple-100 input-md"
                    />
                  </label>

                  <label className="form-control w-full max-w-xs mt-[5px] ">
                    <div className="label">
                      <span className="label-text">Your email</span>
                    </div>
                    <input
                      type="text"
                      data-mod="email"
                      onChange={handleChange}
                      value={email}
                      placeholder="john@doe.com"
                      className="input input-md input-bordered w-full max-w-xs bg-purple-100"
                    />
                  </label>

                  <label className="form-control w-full max-w-xs mt-[5px] ">
                    <div className="label">
                      <span className="label-text">Your phone number</span>
                    </div>
                    <input
                      type="text"
                      data-mod="phone"
                      onChange={handleChange}
                      value={phone}
                      placeholder="+234 911 314 9024"
                      className="input input-md input-bordered w-full max-w-xs bg-purple-100"
                    />
                  </label>
                </div>

                <div className="flex justify-center">
                  <button
                    disabled={disabled}
                    onClick={submit}
                    className=" disabled:bg-slate-500 py-3 font-bold bg-tomato rounded-full text-white text-lg px-[50px] mt-[50px]  hover:bg-tomato"
                  >
                    {loading ? (
                      <div className="flex gap-1">
                        <span>Submitting</span>
                        <span className="loading loading-dots loading-md"></span>
                      </div>
                    ) : (
                      "Let's Go!"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-[10px] md:gap-y-[30px] center-content order-1 lg:order-2  md:mb-[60px]">
          {elements.map((elem, key) => (
            <Element title={elem.title} body={elem.body} key={key} />
          ))}
        </div>
      </div>
    </section>
  );
}
