import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";

import LandingPage from "./pages/landingPage/index";
import Home from "./pages/competition/Home";
import Layout from "./pages/competition/components/Layout";
import Teams from "./pages/competition/Teams";
import Fixtures from "./pages/competition/Fixtures";
import FixtureDetails from "./pages/competition/FixtureDetails";
import Stats from "./pages/competition/Stats";
import Awards from "./pages/competition/Awards";
import TeamDetails from "./pages/competition/TeamDetails";
import PlayerDetails from "./pages/competition/PlayerDetails";

import { logTimeSpent } from "./redux/slices/dataSlice";
import { setKey } from "./redux/slices/configSlice";
import Fans from "./pages/fans";

function App() {
  // utils
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // state
  const [startTime, setStartTime] = useState(0);
  const [theme, setTheme] = useState('versuz');

  // store
  let viewer_key = useSelector((state) => state.config.viewer_key);
  let base_url = useSelector((state) => state.config.base_url);

  // calculate and log time spent on a page
  const calculateAndLogTime = (useCurrentPage) => {
    const endTime = performance.now();
    const time_spent = endTime - startTime;
    let page = "";

    if (time_spent > 0) {
      const split = location.pathname.split("/");
      

      let id = split[1] ? split[1] : "landing-page";
      let page = split[2] ? split[2] : "landing-page";

      if (id) {
        page = split[2] ? split[2] : "home";
      }


      dispatch(
        logTimeSpent({
          time_spent,
          viewer_key,
          page,
          base_url,
          method: "minimize",
          id,
        })
      );
    }

    // Reset the start time
    setStartTime(performance.now());
  };

  // handle page visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // When the tab is hidden (user switches to another tab)
      calculateAndLogTime(false);
    } else {
      // Reset startTime when the tab becomes visible again
      setStartTime(performance.now());
    }
  };

  useEffect(() => {
    localStorage.setItem("start_time", +Date.now());
  }, [document.hidden]);

  // start counter when component loads
  useEffect(() => {
    setStartTime(performance.now());

    if (localStorage.getItem("viewer_key")) {
      viewer_key = localStorage.getItem("viewer_key");
      dispatch(setKey(viewer_key));
    } else {
      let viewer_key = uuidv4();
      localStorage.setItem("viewer_key", viewer_key);
      dispatch(setKey(viewer_key));
    }
  }, []);

  // Handle unload (when the user closes the tab or refreshes the page)
  useEffect(() => {
    const handleUnload = () => {
      calculateAndLogTime(false); // Log time spent before the page unloads
    };

    // Event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleUnload);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [startTime]);

  // handle counter when window location changes
  useEffect(() => {

    
    setStartTime(performance.now());
    const split = location.pathname.split("/");
    let id = split[1] ? split[1] : "landing-page";
    let page = split[2] ? split[2] : "landing-page";

    if (id) {
      page = split[2] ? split[2] : "home";
    }


    // get prev page and start time

    let prevPage = localStorage.getItem("page");
    let prevStartTime = localStorage.getItem("start_time");

    let time = +Date.now();

    let time_spent = time - prevStartTime;

    dispatch(
      logTimeSpent({
        time_spent,
        viewer_key,
        page: prevPage,
        base_url,
        method: "navigate",
        id,
      })
    );

    // console.log(payload)

    localStorage.setItem("page", page);
    localStorage.setItem("start_time", +Date.now());
  }, [location]);

  return (
    <div className="App" >
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path=":id" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="teams" element={<Teams />} />
          <Route path="team/:id" element={<TeamDetails />} />

          <Route path="player/:id" element={<PlayerDetails />} />

          <Route path="fixtures" element={<Fixtures teamId={0} />} />
          <Route path="fixture/:id" element={<FixtureDetails />} />

          <Route path="stats" element={<Stats />} />
          <Route path="awards" element={<Awards />} />
        </Route>

        <Route path="fans/:id" element={<Fans />} />
      </Routes>
    </div>
  );
}

export default App;
