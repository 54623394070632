import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Fixture from "./components/Fixture";
import {Helmet} from "react-helmet";
import Loading from "./components/Loading";

export default function Fixtures({teamId}) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fixtures, setFixtures] = useState([]);

  const base_url = useSelector((state) => state.config.base_url);
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState("");
  const config = useSelector((state) => state.config);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  let path = location.pathname.split("/");
  let url = path[1];

  useEffect(() => {
    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    console.log(`${base_url}api/get_fixtures.php?id=${url}&team=${teamId}`)

    fetch(`${base_url}api/get_fixtures.php?id=${url}&team=${teamId}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {

        let prep = [];
        data.forEach((d) => {
          if (d.fixtures.length) {
            prep.push(d);
          }
        });
        setMonths(prep);
        if(prep[0]){
          setCurrentMonth(prep[0]["month"]);
          setFixtures(prep[0]["fixtures"]);
        }

        setLoading(false)
      })
      .catch((err) => {
        // handle fixture loading error
        console.log(err)
        alert('Failed to load fixtures')
        navigate(-1)
      });
  }, []);

  const handleMonthChange = (e) => {
    const { index, month } = e.target.dataset;
    setFixtures(months[index]["fixtures"]);
    setCurrentMonth(month);
  };

  return (
    <>
    <Helmet>
      <title>{config.name} -  Fixtures</title>
      <meta
      name="description"
      content={`${config.name} -  Fixtures`}
    />
    </Helmet>
    {loading ? <Loading text={'Loading fixtures'} /> : 
      <div className="max-w-[1000px] mx-auto my-[50px] md:my-[80px]">
        <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-start">
          Fixtures
        </h1>
        <div className="flex gap-3">
          {months.map((month, index) => {
            return (
              <button
                key={index}
                data-index={index}
                data-month={month.month}
                onClick={handleMonthChange}
                className={`text-base-100 ${
                  month.month == currentMonth ? "bg-base-100 text-white" : ""
                } border-2 border-base-100 px-5 py-1 rounded-full`}
              >
                {month.month}
              </button>
            );
          })}
        </div>
        <div className="border-black grid gap-[20px] mt-[50px]">
          {fixtures.map((fixture, index) => (
            <Fixture data={fixture} key={index} />
          ))}

          {fixtures.length == 0 && (
            <p className="text-center text-base-100">
              {" "}
              No fixtures for the month of {currentMonth}
            </p>
          )}
        </div>
      </div>
    }
    </>
  );
}
