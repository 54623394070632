import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

import { NavLink } from "react-router-dom";
import Loading from "./components/Loading";

import maleTeam from "../../assets/images/male-team.png"
import femaleTeam from "../../assets/images/female-team.png"

export function TeamLogo({ src, gender }) {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [url, setUrl] = useState(gender == 'male' ? maleTeam : femaleTeam);

  return (
    <>
      <img
        className={`w-[60px] aspect-square self-center rounded-full`}
        src={loading || hasError ? url : src}
        alt=""
        onLoad={() => {
          
          setLoading(false);
          setIsLoaded(true);
        }}
        onError={() => setHasError(true)}
      />
    </>
  );
}

const Team = ({ data }) => {
  const location = useLocation();
  const base_url = useSelector((state) => state.config.base_url);
  let path = location.pathname.split("/");
  let url = path[1];

  const [logo,setLogo] = useState(maleTeam)

  useEffect(()=>{
  
    
  

  },[])

  return (
    <NavLink to={`/${url}/team/${data.id}`}>
      <div className=" p-[20px] flex gap-[20px] bg-base-100">
        <TeamLogo gender={data.gender} src={`${base_url}uploads/${data.logo}`} />
        {/* <img
          className="w-[60px] aspect-square self-center rounded-full "
          
          src={data.logo ? `${base_url}uploads/${data.logo}` : logo}
          alt=""
        /> */}
        <div className="self-center">
          <p className="text-xl  font-bold">{data.name}</p>
          <p className="text-sm ">{data.name}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default function Teams() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState("teams");
  const [teams, setTeams] = useState([]);

  const [loading, setLoading] = useState(true);

  const config = useSelector((state) => state.config);
  const base_url = useSelector((state) => state.config.base_url);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  useEffect(() => {
    let path = location.pathname.split("/");
    let url = path[1];

    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    

    if (config && viewer_key) {
      fetch(`${base_url}api/get_teams.php?id=${url}`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTeams(data.teams);
          setLoading(false)
        })
        .catch((err) => {
          alert("Error getting teams");
          navigate("/");
        });
    }
  }, []);

  useEffect(() => {
    const split = location.pathname.split("/");
    setcurrentPage(split[2]);
  });

  return (
    <>
    <Helmet>
      <title>{config.name} -  Teams</title>
      <meta
      name="description"
      content={`${config.name} -  Teams`}
    />
    </Helmet>
    {
      loading ? <Loading text={"Loading Teams"} />:

      <div className="mt-[50px] lg:mt-[120px]">
        <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-center">
          Teams{" "}
        </h1>
        <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-[30px] max-w-[1200px] mx-auto ">
          {teams.map((team,index) => (
            <Team data={team}  key={index}/>
          ))}
        </div>
      </div>
    }
    </>
  );
}
