import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import maleIcon from "../../assets/images/male-icon.png"
import femaleIcon from "../../assets/images/female-icon.png"

function capitalizeFirstWord(str) {
  // Trim leading and trailing whitespace
  str = str.trim();
  // Capitalize the first letter and concatenate with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function MemberPicture({ src, gender }) {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [url, setUrl] = useState(gender == 'female' ? femaleIcon : maleIcon);

  return (
    <>
      <img
        className={`w-[40px] rounded-full aspect-square self-center`}
        src={loading || hasError ? url : src}
        alt=""
        onLoad={() => {
          
          setLoading(false);
          setIsLoaded(true);
        }}
        onError={() => setHasError(true)}
      />
    </>
  );
}

export const Stat = ({ header, statDetails, playersDetails }) => {
  const base_url = useSelector((state) => state.config.base_url);

  const [players, setPlayers] = useState([]);
  if (!header) {
    header = "";
  }
  let headerarr = header.split("_");
  let headerText = capitalizeFirstWord(headerarr.join(" "));

  useEffect(() => {
    let arr = Object.keys(statDetails);

    let allPlayers = [];

    arr.forEach((item) => {
      let data = {
        data: playersDetails[item],
        count: statDetails[item],
      };

      allPlayers.push(data);
    });
    allPlayers.sort((a, b) => b.count - a.count);

    setPlayers(allPlayers);
  }, []);

  return (
    <>
      {players.length > 0 && (
        <div className="">
          <h1 className=" font-extrabold  text-base-100 text-xl ms-2 mb-2">
            {headerText}
          </h1>
          <div className="rounded-lg h-auto border border-gray-300">
            {/* <div className=" rounded-t-lg h-40 bg-base-100"></div> */}
            {players.map((p, index) => {
              return (
                <>
                  {p.data && index < 5 && (
                    <div
                      className="px-[15px] py-[10px] text-base-100 flex gap-2 border border-b-gray-100"
                      key={index}
                    >
                      <MemberPicture
                      src={`${base_url}uploads/${p.data.picture}`}
                      alt="" />
                      <div className="grid">
                        <span className="text-base">{p.data.name} </span>
                        <span className="text-sm">{" "}</span>
                      </div>
                      <div className="ms-auto text-lg font-bold">{p.count}</div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default function Stats() {
  const stats = ["", "", "", ""];
  return (
    <div className="mt-[50px] md:mt-[80px] max-w-[1400px] mx-auto">
      <h1 className="text-2xl font-extrabold  text-base-100 text-center">
        Player Stats
      </h1>

      <div className="mt-[50px] mx-[20px] lg:mx-0 grid md:grid-cols-2 lg:grid-cols-4 gap-[30px] md:gap-[16px]">
        {stats.map((stat) => (
          <Stat header="Stat" />
        ))}
      </div>

      <h1 className=" mt-[80px] text-2xl font-extrabold  text-base-100 text-center">
        Team Stats
      </h1>

      <div className="mt-[50px] mx-[20px] lg:mx-0 grid md:grid-cols-2 lg:grid-cols-4 gap-[30px] md:gap-[16px]">
        {stats.map((stat) => (
          <Stat header="Stat" />
        ))}
      </div>
    </div>
  );
}
