import React, { useEffect, useState } from "react";
import logo from "../../assets/images/lagos-liga-logo.png";
import logoText from "../../assets/images/logo-text.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import maleIcon from "../../assets/images/male-icon.png";

const Team = ({ data }) => {
  const base_url = useSelector((state) => state.config.base_url);
  return (
    <div className="flex flex-col justify-start content-center col">
      <img
        src={`${base_url}uploads/${data.logo}`}
        className="w-full aspect-square max-w-[70px] self-center"
        alt=""
      />
      <p className="self-center font-bold mt-3 text-base-100 px-2 text-sm text-center">
        {data.name}
      </p>
    </div>
  );
};

const Player = ({ vote, id, data }) => {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const base_url = useSelector((state) => state.config.base_url);
  let name = data.name.split(" ");
  const handleClick = () => {
    vote(data.id);
    document.getElementById("my_modal_1").showModal();
  };
  return (
    <div className=" ">
      <div className="relative border">
        {data.jersey_number && (
          <div className="w-[30px] bg-white aspect-square absolute right-2 top-2 rounded-full flex justify-center border">
            <p className="text-base-100 self-center font-bold">
              data.jersey_number
            </p>
          </div>
        )}

        <img
          src={
            loading || hasError
              ? maleIcon
              : `${base_url}uploads/${data.picture}`
          }
          alt=""
          className="w-full aspect-square"
        />

        <div className=" absolute bottom-0 p-2 bg-base-100 w-full bg-opacity-60">
          <h3 className="font-pilat text-xs text-white font-bold">
            {name[0]}
            <br />
            <span className="text-base font-pilat">
              {name[1] ? name[1] : `${" "}`}
            </span>
            {/* Marcus <br /> Rashford */}
          </h3>
        </div>
      </div>
      <button
        className=" py-2 w-full bg-tomato text-white font-bold text-sm"
        onClick={handleClick}
      >
        Vote
      </button>
    </div>
  );
};

export default function Fans() {
  const location = useLocation();

  let path = location.pathname.split("/");
  let url = path[1];
  let id = path[2];

  const base_url = useSelector((state) => state.config.base_url);
  const config = useSelector((state) => state.config);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [seconds, setSeconds] = useState(8);
  const [ref, setRef] = useState("");

  // competition name, logo
  const [fixtureId, setFixtureId] = useState(0);
  const [competition, setCompetition] = useState({ name: "", logo: "" });
  const [home, setHome] = useState({ name: "", logo: "", goals: 0, pens: 0 });
  const [away, setAway] = useState({ name: "", logo: "", goals: 0, pens: 0 });
  const [players, setPlayers] = useState([]);
  // home team , away team => name,logo, scores, pens
  // contenders

  useEffect(() => {
    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get("ref");

    setRef(ref);

    fetch(
      `${base_url}api/get_fixture_details_mct.php?channel=mct&id=${id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        const { status, data } = json;
        setPageLoading(false);
        setCompetition({
          name: data.competition.name,
          logo: data.competition.logo,
        });
        setHome({
          name: data.home_team.name,
          logo: data.home_team.logo,
          goals: data.home_team.goals.length,
          pens: 0,
        });
        setAway({
          name: data.away_team.name,
          logo: data.away_team.logo,
          goals: data.away_team.goals.length,
          pens: 0,
        });
        setPlayers(data.motm_contenders);
        setFixtureId(data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const vote = (id) => {
    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    fetch(
      `${base_url}api/add_motm_vote.php?id=${id}&fixture_id=${fixtureId}&ref=${ref}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setTimeout(() => {
          setLoading(false);
        }, 2500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!loading) {
      const timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prev) => (prev ? prev - 1 : 0));
        } else {
          return () => clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer); // Cleanup the interval on component unmount
    }
  }, [loading]);

  useEffect(() => {
    if (seconds <= 1) {
      window.location = "https://lagosliga.com/";
    }
  }, [seconds]);

  const goToVersuz = () => {
    window.location = "https://versuz.co";
  };

  return (
    <>
      {pageLoading ? (
        <div
          data-theme="versuz"
          className=" bg-white h-screen flex justify-center relative"
        >
          <div className="flex flex-col self-center">
            <span className="loading loading-dots loading-lg self-center text-base-100"></span>
            <p className="py-2 text-center text-base-100">Loading Fan Page</p>
          </div>
          <footer
            className=" fixed bottom-0 left-0 bg-base-100 p-4 w-full"
            onClick={goToVersuz}
          >
            <p className="text-lime-500 text-center justify-center font-bold flex gap-2 text-sm">
              Powered by{" "}
              <img src={logoText} className="w-[70px] md:w-[80px]" alt="" />
            </p>
          </footer>
        </div>
      ) : (
        <div
          data-theme="versuz"
          className="relative overflow-scroll bg-white font-dm-sans"
        >
          <div className="header p-[30px] flex justify-center bg-white ">
            <img
              className="w-[40px] md:w-[60px]"
              src={`${base_url}uploads/${competition.logo}`}
              alt=""
            />
          </div>

          <div className="teams my-[0px] grid grid-cols-3 max-w-[500px] mx-auto">
            <Team data={home} />
            <div className=" flex flex-col justify-center gap-1">
              <h1 className="text-3xl font-pilat font-bold text-base-100 self-center">
                {" "}
                {home.goals} - {away.goals}
              </h1>
              <p className="self-center text-xs text-base-100 font-pilat mt-1">
                PENALTY
              </p>
              <p className="text-base-100 font-bold self-center">
                {home.pens} - {away.pens}
              </p>
            </div>
            <Team data={away} />
          </div>

          <div className="mt-[40px] md:mt-[50px] px-2">
            <h1 className="font-pilat text-xl text-base-100 font-bold text-center ">
              Vote for your Player <br /> Of The Match
            </h1>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 pt-[40px] pb-[80px] md:max-w-[600px] lg:max-w-[1000px] mx-auto">
              {players.map((player, index) => (
                <Player key={index} data={player} vote={vote} />
              ))}
            </div>
            <dialog id="my_modal_1" className="modal ">
              <div className="modal-box bg-white">
                <div className="flex justify-center my-5">
                  <img className="w-[40px]" src={logo} alt="" />
                </div>

                {loading ? (
                  <div className="flex flex-col ">
                    <span className="loading loading-dots loading-lg self-center text-base-100"></span>
                    <p className="py-2 text-center text-base-100">
                      Submitting your vote
                    </p>
                  </div>
                ) : (
                  <>
                    <h3 className="font-bold text-lg text-base-100 font-pilat text-center">
                      Thanks for voting !
                    </h3>
                    <p className="py-2 text-center text-base-100">
                      The winner will be announced soon
                    </p>
                    <p className="py-3 text-center">
                      {" "}
                      Redirecting in {seconds}
                    </p>
                  </>
                )}
                <div className="modal-action">
                  <form method="dialog">
                    {/* <button className="btn">Close</button> */}
                  </form>
                </div>
              </div>
            </dialog>
          </div>

          <footer
            className=" fixed bottom-0 left-0 bg-base-100 p-4 w-full"
            onClick={goToVersuz}
          >
            <p className="text-lime-500 text-center justify-center font-bold flex gap-2 text-sm">
              Powered by{" "}
              <img src={logoText} className="w-[70px] md:w-[80px]" alt="" />
            </p>
          </footer>
        </div>
      )}
    </>
  );
}
