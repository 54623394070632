import React,{useState,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

export default function LeagueTable({teams,mixed}) {
  const config = useSelector((state) => state.config);
  const [storeTeams, setStoreTeams] = useState([]);

  useEffect(() => {
    if(!teams){
      setStoreTeams(config.table);
    }else{
      setStoreTeams(teams);

    }
  }, [config]);


  return (
    <div className="overflow-x-auto">
        <table className="table">
        <thead className="bg-base-100 text-white">
            <tr className="text-sm md:text-lg ">
            <th>Pos</th>
            <th>Team</th>
            <th>Points</th>
            <th>MP</th>
            <th>W</th>
            <th>L</th>
            <th>D</th>
            <th>GF</th>
            <th>GA</th>
            <th>GD</th>
            {
              !mixed && <>
            <th>Last 5</th>
              </>
            }
            </tr>
        </thead>
        <tbody>
            {storeTeams.map((team, index) => {
            return (
                <tr className="text-base-100 text-sm whitespace-nowrap" key={index}>
                <th scope="row">{index + 1}</th>
                <td className="flex gap-3"> 
                    {/* <img className="w-full max-w-[35px] aspect-square " src="https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/Manchester_United_FC_crest.svg/1200px-Manchester_United_FC_crest.svg.png" alt="" />  */}
                    <span className="self-center pe-3">{team.team_name}</span>
                    </td>
                
                <td>{team.points}</td>
                <td>{team.matches_played}</td>
                <td>{team.matches_won}</td>
                <td>{team.matches_lost}</td>
                <td>{team.matches_drawn}</td>
                <td>{team.goals_for}</td>
                <td>{team.goals_against}</td>
                <td>{team.goal_difference}</td>
                {
                  !mixed &&
                  <>
                <td>W W W W W </td>
                  </>
                }
                </tr>
            );
            })}
        </tbody>
        </table>
    </div>
  );
}