import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import maleTeam from "../../../assets/images/male-team.png"
import femaleTeam from "../../../assets/images/female-team.png"

export function TeamLogo({ src, gender }) {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [url, setUrl] = useState(gender == 'female' ? femaleTeam : maleTeam);

  return (
    <>
      <img
        className={`hidden md:block w-[20px] md:w-[40px] shadow-lg border rounded-full aspect-square self-center mx-[10px] md:mx-[40px]`}
        src={loading || hasError ? url : src}
        alt=""
        onLoad={() => {
          
          setLoading(false);
          setIsLoaded(true);
        }}
        onError={() => setHasError(true)}
      />
    </>
  );
}

const Fixture = (props) => {
  const location = useLocation();

  const { data, handleClick } = props;
  const [homeGoals, setHomeGoals] = useState(0);
  const [awayGoals, setAwayGoals] = useState(0);
  const base_url = useSelector((state) => state.config.base_url);

  let path = location.pathname.split("/");
  let url = path[1];

  useEffect(() => {
    if (data.home_team) {
      let _awayGoals = data.away_team.goals.length;
      let _homeGoals = data.home_team.goals.length;

      data.home_team.goals.forEach((goal) => {
        if (goal.assist == "own_goal") {
          _homeGoals -= 1;
          _awayGoals += 1;
        }
      });

      data.away_team.goals.forEach((goal) => {
        if (goal.assist == "own_goal") {
          _homeGoals += 1;
          _awayGoals -= 1;
        }
      });

      setAwayGoals(_awayGoals);
      setHomeGoals(_homeGoals);
    }
  }, [data]);

  return (
    <NavLink to={`/${url}/fixture/${data.id}`}>
      <div>
        <div className=" text-center mb-1 text-gray-600"> {data.date} </div>
        <div className="bg-gray-100 flex justify-center gap-1 h-[40px] md:h-[60px]">
          
          <div className="flex justify-center md:justify-end flex-1">
            <div className="self-center  h-full flex">
              <span className="text-sm md:text-base  text-base-100 font-bold px-[0px] self-center">
                {data.home_team.name}
              </span>
              <TeamLogo
                src={`${base_url}uploads/${data.home_team.logo}`} 
              />
            </div>
          </div>

          {
            data.started ?
          <div className="flex gap-2 ">
            <div className="bg-base-100 w-[40px] md:w-[60px]  aspect-square text-xl md:text-2xl font-bold flex justify-center">
              <span className="self-center text-[#eec488]">{homeGoals}</span>
            </div>

            <div className="bg-base-100 w-[40px] md:w-[60px]  aspect-square text-xl md:text-2xl font-bold flex justify-center">
              <span className="self-center text-[#eec488]">{awayGoals}</span>
            </div>
          </div> : <div className="bg-base-100 w-[100px] md:w-[140px]  aspect-square text-base md:text-md font-bold flex justify-center">
              <span className="self-center text-[#eec488] text-xs md:text-base">{data.time}</span>
            </div>
          }


          <div className="flex flex-row-reverse justify-center md:justify-end flex-1">
            <div className="self-center  h-full flex flex-row-reverse ">
              <span className="text-sm md:text-base  text-base-100 font-bold px-[0px] self-center">
                {data.away_team.name}
              </span>
              <TeamLogo src={`${base_url}uploads/${data.away_team.logo}`}  />
            </div>
          </div>
          :
        </div>
      </div>
    </NavLink>
  );
};

export default Fixture;
