
import React from 'react'
import { Stat } from "../Stats";

export default function TeamStats({gameStats,playerStats,playerStatDetails,playersDetails}) {

  
    
  return (
    <div className="mt-[50px]">
        <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-start">
          Team Statistics{" "}
        </h1>

        
  
        <div className="mt-4 grid grid-cols-2 md:grid-cols-4  gap-2">
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">
              Games Played
            </p>
            <h1 className="text-center text-4xl font-black">{gameStats.played}</h1>
          </div>
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">
              Won
            </p>
            <h1 className="text-center text-4xl font-black">{gameStats.won}</h1>
          </div>
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">
              Lost
            </p>
            <h1 className="text-center text-4xl font-black">{gameStats.lost}</h1>
          </div>
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">
              Drawn
            </p>
            <h1 className="text-center text-4xl font-black">{gameStats.drawn}</h1>
          </div>
        </div>
  
        <div className="mt-[50px]">
          <h1 className="text-2xl font-extrabold  text-base-100 text-start">
            Player Statistics
          </h1>
  
          <div className="mt-4 mx-[20px] lg:mx-0 grid md:grid-cols-2 lg:grid-cols-3 gap-[30px] md:gap-[16px]">
            {playerStats.map((stat,index) => (
              <Stat key={index} header={stat} statDetails={playerStatDetails[stat]} playersDetails={playersDetails} />
            ))}
          </div>
        </div>
      </div>
  )
}
