import React, { useState } from "react";
import Nav from "./components/Nav";
import Hero from "./components/Hero";
import Solutions from "./components/Solutions";
import Engagements from "./components/Engagement";
import Integrate from "./components/Integrate";
import Footer from "./components/Footer";
import {Helmet} from "react-helmet";



export default function LandingPage() {
  
  return (
    <>
    <Helmet>
    <Helmet>
        <title>Versuz: Your All-in-One Sports Competition Management Platform</title>
      </Helmet>
      <meta
          name="description"
          content="Simplify sports competition management with Versuz. Host events, engage your audience, and connect with sponsors on one seamless platform."
        />
         <meta
          name="keywords"
          content="sports competition management, host sports competitions online, connect with sponsors, manage sports tournaments, audience engagement for sports"
        />
    </Helmet>
      <div data-theme="versuz" className="h-full relative">
        <Nav />

        <Hero />

        <Solutions />

        <Engagements />

        <Integrate />

        <Footer />
        
      </div>
    </>
  );
}
