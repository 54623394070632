import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo-text.png";

const Button = ({ text }) => {};

export default function Nav() {
  const [active, setActive] = useState("home");

  const handleClick = (e) => {
    e.preventDefault();
    const { mod } = e.target.dataset;

    let element;

    switch (mod) {
      case "home":
        element = document.querySelector(".hero");
        break;
      case "solutions":
        element = document.querySelector(".solutions");
        break;
      case "contact":
        element = document.querySelector("footer");
        break;
    }

    element.scrollIntoView();
    setActive(mod);
  };

  

  return (
    <nav className="h-[10vh] bg-lime-500 lg:max-h-[80px] navbar flex justify-between md:px-[40px] fixed top-0 left-0 right-0 shadow-xl z-[999]">
      <span>
        <img src={logo} alt="" />
      </span>

      <div className=" gap-[5px] lg:gap-[20px] justify-center hidden md:flex">
        <a
          href=""
          onClick={handleClick}
          data-mod="home"
          className={`text-sm ${
            active == "home" ? "bg-lime-950 text-lime-500" : "text-lime-950"
          }  px-[20px] py-[5px] rounded-full font-bold`}
        >
          About Us
        </a>

        <a
          href=""
          onClick={handleClick}
          data-mod="solutions"
          className={`text-sm ${
            active == "solutions"
              ? "bg-lime-950 text-lime-500"
              : "text-lime-950"
          }  px-[20px] py-[5px] rounded-full font-bold`}
        >
          Solutions
        </a>

        <a
          href=""
          onClick={handleClick}
          data-mod="contact"
          className={`text-sm ${
            active == "contact" ? "bg-lime-950 text-lime-500" : "text-lime-950"
          }  px-[20px] py-[5px] rounded-full font-bold`}
        >
          Contact Us
        </a>
      </div>

      <div className="flex gap-[20px] justify-end">
        <a
          href="https://competition-manager.versuz.co/"
          target="_blank"
          
          className="text-sm text-lime-950  border-lime-950  border-2 first-line:font-bold px-[20px] py-[5px] rounded-full"
        >
          Login
        </a>
      </div>
    </nav>
  );
}
