import React, { useState } from "react";
import match from "../../../assets/images/match-center.png"
import engagement from "../../../assets/images/engagement.png"
import seo from "../../../assets/images/seo.png"

function Engagement({ title, body,src,index,details }) {
  return (
    <div className="bg-purple-300 rounded-[20px] mx-[20px] md:mx-[20px] lg:mx-[0px] p-[20px] border-white">
      <img src={src} alt="" className="border-4 shadow-lg  border-base-100 w-full mb-5 h-[200px] rounded-[20px]" />
      <p className="text-base-100 mb-[10px] font-bold text-xl text-center">{title}</p>
      <p className="text-base-100  text-justify lg:text-center">{body}</p>
    <div className=" flex">
      

      <button
          onClick={() => document.getElementById(`my_modall_${index}`).showModal()}
          className=" mx-auto btn bg-transparent text-base-100 rounded-full  mt-[25px] px-[20px] py-[5px] border-base-100 border-2 font-bold hover:text-white"
        >
          {" "}
          Learn more
        </button>
    </div>

    <dialog id={`my_modall_${index}`} className="modal modal-top sm:modal-middle ">
        <div className="modal-box  bg-purple-300">
          <img
            src={src}
            alt=""
            className=" bg-purple-100 rounded-[20px] w-full mb-5 h-[200px] border-4 border-base-100"
          />

          <h3 className="font-bold text-2xl text-center text-base-100">{title}</h3>
          
          <p className="py-4 text-base-100 leading-normal text-lg text-justify">
            {details}
          </p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
}

export default function Engagements() {
  const [solutions, setSolutions] = useState([
    {
      title: "Match Center",
      body: "Customizable Websites and widgets, that keep fans up to date, making it easy to follow the action and stay engaged with every game.",
      details: "Create customizable websites and widgets that keep your fans up to date, making it easy for them to follow every game and stay engaged. Whether it's live scores, player stats, or upcoming events, these tools allow you to present real-time updates in a way that keeps your audience connected and excited about the action. Enhance fan experience by offering personalized content, seamless navigation, and interactive features, all designed to keep them engaged and coming back for more.",
      src: match
    },
    {
      title: "Interactive Feedback Features",
      body: "Create custom polls, ratings, and feedback forms to keep audience engagements strong, while collecting valuable insights in real time",
      details: "Engage your audience like never before with custom polls, ratings, and feedback forms. These interactive tools allow you to gather valuable insights in real time, while keeping your audience involved and invested. Whether you're looking to measure fan satisfaction, gather opinions, or make data-driven decisions, these customizable features provide an easy way to collect feedback and strengthen audience engagement. With instant responses, you can quickly adapt and improve, ensuring your audience feels heard and appreciated.",
      src: engagement
    },
    {
      title: "Search Optimized Pages",
      body: "Web pages and widgets are strategically optimized for search, making it easier for existing and new audiences to find you online",
      details: "Our web pages and widgets are strategically optimized for search, ensuring that both existing and new audiences can easily find you online. By leveraging SEO best practices, such as keyword optimization, metadata, and mobile-friendly designs, we help boost your visibility and attract more traffic. Whether it's through search engines or social media, our optimized features ensure that your content reaches the right audience, driving engagement and growth while improving your digital presence.",
      src: seo
    }
  ]);
  return (
    <section
      data-theme="versuz"
      className="solutions  rounded-[40px] px-[20px] md:px-[50px] py-[80px] max-w-[1400px] mx-auto mt-[100px] text-base-100 "
    >
      <h1 className="text-3xl text-center font-semibold text-purple-100">
        {" "}
        Innovative Tools for   <br />{" "}
        <span className="text-tomato">Enhanced</span> Fan Engagement
      </h1>

      <p className="text-purple-100 max-w-[606px] text-cemter mx-auto mt-[18px]">
        Streamline every aspect of your competition—from team registrations and
        match scheduling to real-time score updates and automated notifications.{" "}
      </p>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-[50px] gap-[22px]">
        {solutions.map((sol, index) => (
          <Engagement key={index}  index={index} title={sol.title} body={sol.body} details={sol.details} src={sol.src} />
        ))}
      </div>
    </section>
  );
}
